@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: local('Gotham Bold'), url(./assets/fonts/Gotham-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 200;
  src: local('Gotham Book'), url(./assets/fonts/Gotham-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 100;
  src: local('Gotham Book'), url(./assets/fonts/Gotham-Light.otf) format('opentype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes rotateElement2 {
  0% {
    transform: rotate(40deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

.el2 {
  transform-origin: top;
  animation: 1.2s ease 0s 1 rotateElement2;
  transform: rotate(0);
}